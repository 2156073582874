<template>
    <v-container :class="{ 'margin-auto': $vuetify.breakpoint.mdAndUp }">
        <template v-if="favourites.length">
            <v-row justify="center" class="p-relative zindex-1">
                <v-col cols="12" align="center" justify="center" v-if="$vuetify.breakpoint.mdAndUp">
                    <h3 class="heading--text">My Favourites</h3>
                    <p class="muted-1 ma-0">Click the tiles to go back to your favourites readings and meditations.</p>
                </v-col>

                <v-col cols="12" md="4" lg="3" v-for="(item, index) in favourites" :key="index">
                    <template v-if="$vuetify.breakpoint.smAndDown">
                        <meo-list-item
                            :contact="contact"
                            section
                            favourite
                            :item="item"
                            @click.native="goTo(item)"
                        ></meo-list-item>
                    </template>

                    <template v-else>
                        <meo-card-item
                            :contact="contact"
                            section
                            favourite
                            :item="item"
                            @click.native="goTo(item)"
                        ></meo-card-item>
                    </template>
                </v-col>
            </v-row>
        </template>

        <template v-else>
            <v-row class="p-relative zindex-1" align="center" justify="center">
                <v-col cols="12">
                    <p class="text-center">
                        You haven't selected any favourites! To do this, click the
                        <v-icon color="dark">mdi-heart</v-icon> icon on any section throughout the app.
                    </p>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
// Components
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoCardItem from "@/components/misc/meo-card-item.vue";

// Services
import Contact from "@/modules/meo-module/contact";

export default {
    name: "profile-favourites",

    meta: {
        titleTemplate: "Favourites |  %s",
    },

    props: {
        sections: {
            type: Array,
        },
    },

    components: {
        MeoListItem,
        MeoCardItem,
    },

    data() {
        return {
            favourites: [],
            contact: {},
        };
    },

    methods: {
        async init() {
            this.$meo.loading = true;

            let favourites = [];
            let user = this.$meo.user;
            let contactId = user.contacts && user.contacts[0];

            if (!contactId) {
                user = await this.$fluro.api.get("/session", { cache: false }).then(({ data }) => data);
                contactId = user.contacts && user.contacts[0];
            }

            let criteria = {
                includeUnmatched: true,
                allDefinitions: true,
                select: ["data", "items"],
                sort: {
                    key: "title",
                    direction: "asc",
                    type: "string",
                },
                filter: {
                    operator: "and",
                    filters: [
                        {
                            key: "status",
                            comparator: "in",
                            values: ["active"],
                        },
                    ],
                },
            };

            // Filter out bundle
            let freeModules = await this.$fluro.content.filter("meoModule", criteria);
            freeModules = freeModules.filter((item) => item.data && item.data.freeProduct);
            let freeIds = [];

            freeModules.forEach((item) => {
                let ids = item.items.map((id) => id);
                freeIds = freeIds.concat(ids);
            });

            let freeScreens = await this.$fluro.content.getMultiple("", freeIds, {});

            freeModules.forEach((item) => {
                var array = [];

                item.items.forEach((id) => {
                    let match = freeScreens.find((screen) => screen._id == id);
                    if (match) array.push(match);
                });

                item.items = array;
            });

            let products = await this.$fluro.api.get("/my/products/meoModuleScreen").then(({ data }) => data);
            products = products.concat(freeModules);

            let contact = {};
            console.log(contactId);

            if (contactId) {
                contact = await Contact.get(contactId).then(({ data }) => data);
                this.contact = contact;
                this.$localProgress.getLocalFavourites(contact);
            }
            console.log(contact, "contact");

            let ids = contact.data && contact.data.favourites;
            if (!ids) {
                let localFavourites = JSON.parse(window.localStorage.getItem("favourites"));
                ids = localFavourites;
            }

            if (ids && ids.length) {
                products.forEach((product) => {
                    let matches = product.items.filter((item) => ids.includes(item._id));
                    matches.forEach((match) => {
                        let productId = product._id;
                        let screenId = match.data && match._id;
                        let title = product.title;
                        let description = (match.data && match.data.displayTitle) || match.title;
                        let icon = (match.data && match.data.icon) || "";

                        favourites.push({
                            productId,
                            screenId,
                            title,
                            description,
                            icon,
                        });
                    });
                });
            }
            this.favourites = favourites;

            console.log(this.favourites);

            this.$meo.loading = false;
        },
        goTo(item) {
            this.$router.push({ name: "module.screen", params: { id: item.productId, screenId: item.screenId } });
        },
    },

    async created() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
